.top-menu {
  background-color: #fcfcfc;
  padding: 0.7rem 0;
  margin-bottom: 20px;
  margin-top: -20px;
  z-index: 1;
}

body.dark .top-menu {
  background-color: var(--gray9);
}

.top-menu__items {
  display: flex;
  align-items: center;
  margin: 0;
}

.top-menu__item {
  display: inline-block;
  color: var(--primary-color);
}

body.dark .top-menu__item {
  color: inherit;
}

.top-menu__item .icon {
  font-size: 25px;
  color: var(--primary-color);
  position: relative;
  top: 0.25rem;
  left: -0.2rem;
}

body.dark .top-menu__item .icon {
  color: var(--gray3);
}

.top-menu__title {
  position: relative;
  top: -0.15rem;
}
