.song-parts {
  margin-bottom: 1rem;
}

.song-parts__part {
  padding: 0.3em;
  cursor: default;
  display: flex;
  justify-content: space-between;
}

.song-parts__part .icon {
  position: relative;
  top: 0.2em;
}

.song-parts .song-parts__part:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.song-parts .song-parts__part:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.song-parts__controls .icon {
  margin: 0 0.1rem;
}

/* DARK THEME */

body.dark .song-parts .icon {
  color: var(--gray0);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 768px) {
  .song-parts__info .icon {
    top: 0.5rem;
  }

  .song-parts__name {
    position: relative;
    top: 0.2rem;
    left: 0.2rem;
  }

  .song-parts__controls .icon {
    font-size: 1.6rem;
    margin: 0 0.5rem;
    position: relative;
    top: 0.2rem;
  }

  .song-parts__part {
    min-height: 44px;
  }
}
