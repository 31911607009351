.page-item.active .page-link {
  background-color: var(--primary-color);
}

/* DARK THEME */

body.dark .page-item .page-link {
  background-color: var(--gray8);
  border-color: var(--gray8);
  color: var(--gray1);
}

body.dark .page-item.active .page-link {
  background-color: var(--gray6);
  border-color: var(--gray6);
}
