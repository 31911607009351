.search-songs {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.search-songs h2.title {
  color: var(--primary-color);
}

.search-songs h2.title .icon {
  position: relative;
  top: 0.3rem;
}

.search-songs p.sub-title {
  padding: 0.5rem 0;
}

.search-songs .search-box-container {
  max-width: 700px;
  margin: 0 auto 0.5rem auto;
}

.search-songs table {
  text-align: left;
  max-width: 800px;
  margin: auto;
}

.search-songs table td {
  position: relative;
  top: 0.4rem;
  padding: 0.6rem 0.3rem;
}

.search-songs .not-found {
  margin-top: 2rem;
}

.search-songs .not-found .icon {
  font-size: 32px;
}

.search-songs .song-count {
  max-width: 700px;
  margin: auto;
}

.search-songs .song-count p {
  text-align: right;
  position: relative;
  right: 0.2rem;
  top: -0.4rem;
}

.search-songs .song-results {
  text-align: left;
  max-width: 700px;
  margin: auto;
}

.search-songs .song-results .song-box {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.123);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.search-songs .song-box .btn {
  border-radius: 40px;
  margin: 0.5rem 0.5rem 0 0;
}

/* DARK THEME */

/* song result box */

body.dark .search-songs .song-results .song-box {
  background-color: var(--gray8);
}
