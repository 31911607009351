#search-box-scroll-id {
  scroll-margin-top: 55px;
}

.search-box-container {
  position: relative;
}

.search-box {
  padding: 1.2em 1em;
  border-radius: 40px;
}

.search-box-container span {
  position: absolute;
  right: 0.9rem;
  top: 0.6rem;
  z-index: 0;
}

.search-box-container span .icon {
  color: var(--gray3);
}

.search-box-container .search-clear-btn .icon {
  font-size: 28px;
  position: relative;
  top: -0.2rem;
  left: 0.1rem;
}
