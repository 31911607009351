.faq {
  margin: auto;
  max-width: 700px;
  margin-top: 3rem;
}

.faq .topic {
  padding-bottom: 1rem;
}

.faq .topic-title {
  font-weight: 700;
}
