.footer {
  padding: 2rem 0;
  text-align: center;
  background-color: #fff;
  color: rgb(165, 165, 165);
}

.footer a {
  color: rgb(165, 165, 165);
}
