:root {
  --primary-color: #2d76e4;
  --primary-color-lighter: #5791e9;
  --primary-color-pastel: #def7fd;
  --secondary-color: #a541ff;
  --secondary-color-lighter: #b767ff;
  --secondary-color-pastel: #ebdefd;
  --red-color: #d81d26;
  --red-color-lighter: #dd4a52;
  --shadow-color: #f8f9fa;
  --text-primary: rgb(36, 36, 36);
  --text-disabled: rgb(158, 158, 158);
  --gray0: #fff;
  --gray1: #f8f8f8;
  --gray2: #f1f1f1;
  --gray2-1: #d5d5d5;
  --gray3: #bebebe;
  --gray4: #aaaaaa;
  --gray4-1: #858585;
  --gray5: #868686;
  --gray6: #333333;
  --gray7: #292929;
  --gray8: #222222;
  --gray9: #1b1b1b;
  --gray9-1: #181818;
  --gray10: #141414;
}

.no-new-teams {
  font-size: 0.9rem;
  padding: 10px;
  background-color: var(--gray2);
  border-radius: 20px;
  margin-bottom: 20px;
}

.light {
  --bg: var(--gray0);
  --bg-text: var(--gray10);
}

.dark {
  --bg: var(--gray10);
  --bg-text: var(--gray3);
}

body {
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  background: var(--bg);
  color: var(--bg-text);
}

/* Reusable */

a {
  color: var(--primary-color);
}

ul {
  list-style-type: none;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.horizontal-menu {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.horizontal-menu::-webkit-scrollbar {
  display: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.bg-color {
  background-color: var(--primary-color);
}

.text-color,
.text-color:hover {
  color: var(--primary-color);
}

.icon {
  svg {
    width: 20px;
    stroke: var(--primary-color);
  }

  &.solid {
    svg {
      fill: var(--primary-color);
    }
  }

  &.play-icon,
  &.options-icon {
    svg {
      width: 32px;
    }
  }

  &.previous-song-icon,
  &.next-song-icon {
    svg {
      width: 48px;
      stroke: transparent;
    }
  }
}

.effect {
  animation: effect 0.5s;
}

.effect-user {
  animation: effect-user 1s;
}

@keyframes effect {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes effect-user {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.max-width {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.no-action {
  cursor: default;
  opacity: 0.5;
}

.title span {
  position: relative;
  top: -0.5rem;
}

.title .icon {
  font-size: 32px;
}

.vertical-center {
  margin-top: 2rem;
}

/* Custom Bootstrap */

.badge-primary {
  background-color: var(--primary-color);
}

.list-group-item {
  background: inherit;
}

.table-hover tbody tr:hover {
  color: inherit;
}

.btn-primary {
  background-color: var(--primary-color);
  background: linear-gradient(135deg, #5d95ea, #2d76e4);
}

.btn-primary:hover {
  background: linear-gradient(135deg, #5d95eaee, #2d76e4ee);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary-color);
  background: linear-gradient(135deg, #5d95ea, #2d76e4);
}

.table {
  color: inherit;
}

/* Forms */

.form-logo {
  display: block;
  margin: auto;
  height: 75px;
  margin-bottom: 20px;
}

.form-signup .form-logo {
  margin-bottom: 10px;
}

.form-signin .forgot-password {
  position: relative;
  top: -0.8rem;
}

.form-signin,
.form-signup,
.form-reset,
.form-reset-new {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-control {
  padding: 20px 10px;
}

/* rc-tooltip */

.rc-tooltip {
  opacity: 1 !important;
}

.lyrics-tooltip {
  white-space: pre-wrap;
}

.rc-tooltip-inner {
  background-color: var(--gray10) !important;
  color: var(--gray3) !important;
  border: none !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: var(--gray10) !important;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: var(--gray10) !important;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-left-color: var(--gray10) !important;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: var(--gray10) !important;
}

/* Sortable */

.drag-handle {
  cursor: grab;
}

/* DARK THEME */

body.dark {
  --primary-color: var(--primary-color-lighter);
  --secondary-color: var(--secondary-color-lighter);
}

body.dark .badge-primary {
  background-color: #2d76e4;
}

body.dark .custom-select {
  background: var(--gray7)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgb(200,200,200)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border-color: var(--gray7);
  color: inherit;
}

body.dark .form-control {
  background: var(--gray7);
  color: inherit;
  border-color: var(--gray7);
}
