.song-form {
  margin-bottom: 2rem;
}

.song-form__input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.song-form__input-group .form-group {
  flex: 1 0 0px;
}

.song-form .song-form__input-group .custom-select {
  height: 42px;
}

.song-form__title {
  font-weight: bold;
}

.song-form #currentLyrics {
  padding: 10px 10px 18px;
}

.song-form #partNumber {
  padding: 10px;
}

.song-form__submit-btn {
  width: 120px;
  margin-left: auto;
}

@media only screen and (max-width: 991px) {
  .song-form__submit-btn {
    width: 100%;
  }
}

.song-form .team-icon .icon {
  position: relative;
  top: 0.3rem;
}

/* Undo Toast */

.Toastify__toast-icon + div {
  width: 100%;
}

.undo-toast {
  display: flex;
  justify-content: space-between;
}

.undo-toast__undo {
  cursor: pointer;
  color: var(--primary-color);
  padding: 0 1rem;
  transition: color 0.3s;
}

.undo-toast__undo:hover {
  color: var(--secondary-color);
}
