/* Services */

.services-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.3rem;
}

/* Service */

.service {
  padding: 0.7rem;
  margin: 1rem 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000011;
}

.service:first-child {
  margin-top: 0;
}

.service-flex-container {
  display: flex;
  margin-bottom: 1rem;
}

.service-info {
  flex-grow: 1;
}

.service-actions,
.service-actions-a {
  flex-shrink: 0;

  svg {
    position: relative;
    top: -6px;
  }
}

.service-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
}

/* service actions */

/* admin */

.service-actions-a {
  position: relative;
  left: 0.7rem;
}

/* user */

.service-actions {
  position: relative;
  left: 0.1rem;
}

/* Service Date */

.service-date {
  font-size: 0.9rem;
}

/* Service Tags */

.service__tags {
  display: flex;
}

.service__tag {
  color: #fff;
  padding: 0 0.6rem;
  border-radius: 20px;
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  display: inline-block;
}

.song-count {
  color: var(--text-primary);
  padding: 0 !important;
  padding-right: 0.3rem !important;
}

.song-count-tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-count-icon {
  padding-right: 0.1rem;
  position: relative;
  top: 0.1rem;
}

.playlist {
  background: linear-gradient(135deg, #db4f4f, #aa2de4);
  background-color: #e42d79;
}

.playlist a {
  text-decoration: none;
  color: #fff;
}

.services .days {
  background: linear-gradient(135deg, #4a87e2, #1e6add);
  background-color: var(--primary-color);
}

.services .tomorrow {
  background: linear-gradient(135deg, #c445dd, #aa2de4);
  background-color: #aa2de4;
}

.services .today {
  background: linear-gradient(to right, #cb2d3e, #ef473a);
  background-color: #cb2d3e;
}

.services .past {
  background: var(--gray2);
  color: var(--text-primary);
}

/* service options */

.services .btn-delete {
  position: relative;
  top: -0.1rem;
}

.services .btn-delete .icon {
  top: 0.3rem;
}

.service .view-service-control .icon,
.service .option-icon .icon {
  font-size: 32px;
}

/* DARK THEME */

/* Services */

body.dark .service {
  box-shadow: none;
  background-color: var(--gray9);
}

/* Service tags */

body.dark .song-count {
  color: inherit;
}

body.dark .playlist a {
  text-decoration: none;
  color: #ff8080 !important;
}

body.dark .playlist {
  background: rgb(43, 34, 34);
}

body.dark .services .today {
  background: #ff6161;
  color: var(--gray10);
}

body.dark .services .tomorrow {
  background: var(--secondary-color-lighter);
  color: var(--gray10);
}

body.dark .services .days {
  background: var(--primary-color);
  color: var(--gray10);
}

body.dark .services .past {
  background: var(--gray7);
  color: var(--gray3);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 992px) {
  .service .view-service-control .icon,
  .service .option-icon .icon {
    font-size: 42px;
  }

  .service__tag {
    padding: 0 0.6rem;
  }

  .service-actions,
  .service-actions-a {
    svg {
      position: relative;
      top: -15px;
    }
}
}
