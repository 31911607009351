.songs ul.categories {
  padding: 0.5rem 0 0.2rem 0;
  margin: 0;
  list-style-type: none;
  font-size: 0.9rem;
}

.songs ul.categories li {
  cursor: pointer;
  display: inline-block;
  padding: 0.2rem 0.6rem;
  margin-right: 0.5rem;
  border-radius: 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.068);
  background-color: #f8f8f8;
  transition: background-color 0.1s;
}

.songs ul.categories li:hover {
  background-color: #f1f1f1;
}

.songs ul.categories li.active {
  color: #fff;
  background-color: var(--primary-color);
}

.songs .form-group {
  margin-bottom: 0.3rem;
}

.songs #song-count {
  position: relative;
  top: 0.1rem;
  margin-left: auto;
  flex-shrink: 0;
  font-size: 0.9rem;
  padding-left: 0.4rem;
}

.songs #song-count::before {
  position: absolute;
  left: -1.2rem;
  top: -0.3rem;
  height: 33px;
  width: 20px;
  content: '';
  background: linear-gradient(to left, var(--gray0) 10%, rgba(255, 255, 255, 0) 80%);
}

/* DARK THEME */

body.dark .songs ul.categories li {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.068);
  background-color: var(--gray7);
}

body.dark .songs ul.categories li:hover {
  background-color: var(--gray8);
}

body.dark .songs ul.categories li.active {
  color: #fff;
  background-color: #2d76e4;
}

body.dark .songs #song-count::before {
  background: linear-gradient(to left, var(--gray10) 10%, rgba(31, 31, 31, 0) 80%);
}
