.no-content .icon {
  position: relative;
  font-size: 26px;
  top: 0.2rem;
}

.no-content span {
  position: relative;
  top: -0.25rem;
}

.no-content svg {
  fill: #2d76e4;
}

.no-content-loader {
  margin-bottom: 1.15rem;
}

/* DARK THEME */

body.dark .no-content .icon {
  color: var(--gray3);
}

body.dark .no-content svg {
  fill: var(--gray3);
}
