.songs {
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .songs__table {
    min-height: 500px;
  }
}

.songs tbody .icon {
  font-size: 25px;
}

.search-icon .icon {
  color: var(--text-disabled);
}

/* songs menu */

.songs-menu-container {
  background-color: #fcfcfc;
  padding: 0.4rem 0;
  margin-bottom: 20px;
  margin-top: -20px;
  z-index: 1;
}

ul.songs-menu {
  padding: 0;
  margin: 0;
}

ul.songs-menu .icon {
  position: relative;
  top: 0.3rem;
  font-size: 22px;
}

.songs-menu li {
  margin: 0.1rem 1.7rem 0.5rem 0;
  display: inline-block;
}

.songs-menu .btn:hover {
  text-decoration: none;
}

.songs-menu .icon[name='add-circle'] {
  font-size: 25px;
  position: relative;
  top: 0.4rem;
}

.songs-menu .icon[name='add-circle'] + .label {
  position: relative;
  top: 0rem;
}

.songs-menu span.label {
  color: var(--primary-color);
}

.songs-menu .service-count {
  color: #fff;
  background-color: var(--primary-color);
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
}

/* DARK THEME */

body.dark .songs-menu-container {
  background-color: var(--gray9);
}

body.dark .songs-menu-container .icon {
  color: var(--gray3);
}

body.dark .songs-menu-container span {
  color: var(--gray3);
}

body.dark .songs-menu .service-count {
  background-color: var(--gray7);
}
