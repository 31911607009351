/* Nav Bar */

.color-bar {
  background-color: var(--primary-color);
  height: 5px;
  width: 100%;
}

.navbar {
  background: #fff;
  color: var(--primary-color);
  padding: 0;
  margin: 0 0 20px 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.123);
  z-index: 2;
}

.navbar-brand {
  cursor: pointer;
}

.navbar #logo {
  height: 60px;
}

.navbar a:link,
.navbar a:visited {
  position: relative;
  color: var(--primary-color);
  transition: color 0.3s;
}

.navbar a.active {
  border-radius: 0.3rem;
  background-color: #f4f8ff;
}

/* Dropdown */

.navbar .dropdown {
  position: absolute;
  right: 0;
  margin-right: 15px;
  top: 20px;
}

.navbar #dropdown-menu {
  position: relative;
  top: -0.4rem;
}

// Dropdown Text
.navbar #dropdown-menu span {
  position: relative;
  top: 0.1rem;
}

// Dropdown Icon
.navbar #dropdown-menu .icon {
  position: relative;
  top: 0rem;
}

// Dropdown Menu Icons
.navbar .dropdown-item .icon {
  position: relative;
  top: -0.24rem;
}

.navbar .dropdown-item .dropdown-text {
  position: relative;
  top: -0.15rem;
}

.navbar a.dropdown-item:active {
  background-color: var(--shadow-color);
}

span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(45, 118, 228)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

// Nav Bar Icon
.navbar {
  .icon {
    color: var(--primary-color);
    position: relative;
    top: -2px;
    margin-right: 5px;
  }
}

/* DARK THEME */

body.dark .navbar a {
  color: var(--gray3);
}

body.dark .navbar .icon {
  color: var(--gray3);
}

body.dark .navbar {
  background: var(--gray8);
}

body.dark .navbar a.active {
  background-color: var(--gray7);
}

body.dark h5.title .icon {
  color: var(--gray3);
}

body.dark .dropdown-menu {
  background: var(--gray8);
}

body.dark .dropdown-menu .dropdown-item:hover {
  background: var(--gray7);
}

body.dark .dropdown-menu .dropdown-divider {
  border-top: 1px solid var(--gray7);
}

body.dark span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(87, 145, 233)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* MEDIA QUERIES */

@media only screen and (max-width: 991px) {
  .navbar {
    .navbar-nav .dropdown {
      position: relative;
      top: 5px;
      margin-right: 0;
    }

    a.active {
      border-radius: 0;
    }

    #dropdown-menu {
      outline: none;
    }
  } 
}

@media only screen and (max-width: 768px) {
  nav a.nav-link {
    padding-left: 0.7rem !important;
  }

  .navbar #logo {
    padding-left: 0.5rem;
  }
}
