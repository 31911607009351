.modal .icon {
  display: inline-block;
  position: relative;
  top: 0.3rem;
}

/* DARK THEME */

body.dark .modal .modal-content {
  background: var(--gray8);
}

body.dark .modal .close {
  color: var(--gray3);
}

body.dark .modal .modal-header {
  border-bottom: 1px solid var(--gray7);
}

body.dark .modal .modal-footer {
  border-top: 1px solid var(--gray7);
}
