#home {
  background: #2d76e483;
  background-image: url('../../images/bg.svg');
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    url('../../images/bg.svg');
  background-position: center;
  background-repeat: repeat;
  background-attachment: local;
  color: #fff;
  padding-bottom: 5rem;
}
#home .navbar {
  background-color: rgba(255, 255, 255, 0.774);
  box-shadow: none;
}

#home .jumbotron {
  padding-top: 5rem;
  background-color: transparent;
  color: #343434;
}

#home h1 {
  font-weight: 700;
}

#home .lead {
  max-width: 530px;
  padding: 1rem 0;
}

/* Buttons */

#home .btn {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.7rem 1.2rem;
  border-radius: 30px;
  transition: 0.3s ease-in-out;
}

#home .btn:hover {
  background-color: #296acd;
}

#home .btn-signin {
  padding: 0.4rem 0.9rem;
}

/* Features */

#features {
  text-align: center;
  background-color: #fff !important;
  color: #343434;
}

#features h2 {
  font-weight: 700;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

#features .first-row {
  margin-bottom: 5rem;
}

/* Feature Box */

#features .feature-box img {
  width: 100px;
}

#features .feature-box .title {
  font-weight: 700;
  padding-top: 0.7rem;
}

#features .feature-box .desc {
  padding: 0 2rem;
}

/* Create Account */

#create-account {
  color: #343434;
  text-align: center;
}

#create-account h2 {
  padding-top: 3.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 991px) {
  #features .first-row {
    margin-bottom: 0;
  }

  #features h2 {
    padding-top: 2rem;
  }

  #features .feature-box {
    padding-bottom: 3rem;
  }

  #features .feature-box .desc {
    font-size: 1.2rem;
  }

  #features .feature-box .title {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 415px) {
  #home h1 {
    font-size: 2rem;
  }
}
