.service-song {
  display: flex;
  margin-bottom: 7px;
  min-height: 93px;
}

.service-song__category-praise {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-pastel) !important;
}

.service-song__category-worship {
  color: var(--secondary-color) !important;
  background-color: var(--secondary-color-pastel) !important;
}

body.dark .service-song__category-praise {
  color: var(--gray10) !important;
  background-color: var(--primary-color) !important;
}

body.dark .service-song__category-worship {
  color: var(--gray10) !important;
  background-color: var(--secondary-color) !important;
}

/* LEFT CONTAINER */
.service-song__left-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color-pastel);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  min-width: 64px;
}

.service-song__category-wrapper {
  padding: 25px 0;
  text-align: center;
}

.service-song__icon {
  display: flex;
  justify-content: center;
}

.service-song__icon .icon {
  font-size: 25px;
}

.service-song__category {
  font-size: 12px;
  font-weight: 600;
}

/* MIDDLE CONTAINER */
.service-song__middle-container {
  display: flex;
  flex-direction: column;
  /* 64 left + 64 right containers */
  width: calc(100% - 128px);
  border: 1px solid var(--gray1);
  background-color: var(--gray0);
}

body.dark .service-song__middle-container {
  background-color: var(--gray9);
  border: 1px solid var(--gray9);
}

.service-song__info-wrapper {
  height: 100%;
  padding: 5px 10px 0 10px;
}

.service-song__actions-wrapper {
  padding: 0 0 10px 10px;
}

.service-song__title {
  font-weight: 600;
}

.service-song__artist {
  font-size: 14px;
  font-weight: 500;
}

.service-song__actions-wrapper {
  display: flex;
  align-items: center;
}

.service-song__remove-btn {
  background-color: var(--gray1);
  border-radius: 4px;
  color: var(--gray4-1);
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  max-width: 64px;
  padding: 2px 8px;
  text-align: center;
  text-transform: uppercase;
}

.service-song__remove-btn span {
  position: relative;
  top: -1px;
}

body.dark .service-song__remove-btn {
  background-color: var(--gray8);
}

/* RIGHT CONTAINER */
.service-song__right-container {
  display: flex;
  flex-direction: column;
  background-color: var(--gray1);
  min-width: 64px;
  text-align: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

body.dark .service-song__right-container {
  background-color: var(--gray8);
}
.service-song__key-wrapper,
.service-song__bpm-wrapper {
  font-size: 20px;
  font-weight: 700;
  color: var(--gray4-1);
  height: 50%;
}

body.dark .service-song__key-wrapper,
body.dark .service-song__bpm-wrapper {
  color: var(--gray5);
}

.service-song__key-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray2-1);
}

body.dark .service-song__key-wrapper {
  border-bottom: 1px solid var(--gray9);
}

.service-song__bpm-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-song__bpm-text {
  font-size: 12px;
  font-weight: 400;
  display: block;
  position: relative;
  top: -5px;
}
