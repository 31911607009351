.plugin__body {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
  text-align: center;
  border-radius: 0.3rem;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 0 2em;
}

.plugin__toggle {
  position: relative;
  top: 0.35em;
  font-size: 0.8em;
}

.plugin__body.on {
  background-color: var(--primary-color);
  color: var(--gray0);
}

/* Dark Mode */

body.dark .plugin__body.on {
  background-color: var(--primary-color);
  color: var(--gray10);
}

body.dark .plugin__body.off {
  color: var(--gray3);
  border-color: var(--gray3);
}
