.options {
  float: right;
}

.options a {
  padding: 0;
  outline: none;
}

.options button:active {
  background-color: var(--shadow-color);
}

.options__icon {
  position: relative;
  top: 0.2rem;
  font-size: 25px !important;
}

.options__title {
  color: var(--primary-color);
  position: relative;
  top: -0.2rem;
  margin-left: 0.1rem;
}

.options__delete .options__title {
  top: -0.25rem;
}

.options__delete .options__title,
.options__delete .options__icon {
  color: var(--red-color);
}

/* DARK THEME */
body.dark .options__delete .options__title,
body.dark .options__delete .options__icon {
  color: var(--red-color-lighter);
}
