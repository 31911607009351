.service-form .title {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 991px) {
  .service-form .title {
    margin-bottom: 1rem;
  }
}

.service-form__right {
  padding-left: 2rem;
}

@media only screen and (max-width: 991px) {
  .service-form__right {
    padding-left: 0;
  }
}

.service-form__heading {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.service-form__song-count {
  background-color: var(--primary-color);
  border-radius: 6px;
  color: #fff;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  margin-left: 0.1rem;
  text-align: center;
}

body.dark .service-form__song-count {
  color: var(--gray10);
}

.service-form .date {
  visibility: hidden;
}

.service-form .form-row .title {
  margin-bottom: 0.8rem;
}

.service-form .badge-pill {
  font-size: 1rem;
}

/*** Calendar ***/

.react-calendar {
  font-family: inherit;
  font-size: 0.8rem;
  border: none;
  margin: auto;
  width: 100%;
  margin-bottom: 0.5rem;
}

.react-calendar__tile {
  margin: 0.3rem 0 !important;
}

/* Hover */

.react-calendar__tile:hover {
  background-color: transparent !important;
}

.react-calendar__tile:disabled:hover {
  background-color: #f0f0f0 !important;
}

body.dark .react-calendar__tile:disabled:hover {
  background-color: var(--gray8) !important;
}

/* First Disabled Calendar Item */

.react-calendar__month-view__days > .react-calendar__tile:disabled:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

/* Now */

.react-calendar__tile--now {
  background-color: var(--primary-color);
  color: var(--gray0) !important;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.react-calendar__tile--now:hover {
  background-color: var(--primary-color) !important;
  color: var(--gray0);
}

.react-calendar__tile--now:hover {
  background-color: var(--primary-color) !important;
  color: var(--gray0);
}

/* Active */

.react-calendar__tile--active {
  background-color: var(--secondary-color);
  color: var(--gray0) !important;
  border-radius: 25px;
}

.react-calendar__tile--active:focus {
  background-color: var(--secondary-color) !important;
}

.react-calendar__tile--active:hover {
  background-color: var(--secondary-color) !important;
}

/* Now + Active */

.react-calendar__tile--now.react-calendar__tile--active {
  background-color: var(--secondary-color);
  color: var(--gray0) !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

/* Weekend */

.react-calendar__month-view__days__day--weekend {
  color: var(--primary-color);
}

/* React Calendar Dark Theme */

body.dark .react-calendar {
  background: inherit;
}

body.dark .react-calendar__navigation button {
  background-color: var(--gray10);
  color: var(--gray3);
}

body.dark .react-calendar__navigation button:enabled:hover,
body.dark .react-calendar__navigation button:enabled:focus {
  background-color: var(--gray7);
  color: var(--gray3);
}
body.dark .react-calendar__navigation button[disabled] {
  background-color: var(--gray8) !important;
  color: var(--gray6) !important;
}

body.dark .react-calendar__month-view__days__day {
  color: var(--gray0);
}

body.dark .react-calendar__year-view__months__month,
body.dark .react-calendar__decade-view__years__year,
body.dark .react-calendar__century-view__decades__decade {
  color: var(--gray3);
}

body.dark .react-calendar__tile:disabled {
  background-color: var(--gray8);
  color: var(--gray6);
}

body.dark .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

body.dark .react-calendar__tile:enabled:hover,
body.dark .react-calendar__tile:enabled:focus {
  background-color: var(--gray7);
}

body.dark .react-calendar__tile--hasActive {
  background: var(--primary-color);
}
body.dark .react-calendar__tile--hasActive:enabled:hover,
body.dark .react-calendar__tile--hasActive:enabled:focus {
  background: var(--primary-color);
}

body.dark .react-calendar__month-view__days__day--weekend {
  color: var(--primary-color);
}
